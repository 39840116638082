import React from 'react'
import './footer/_style/Footer.css'

const Footer = () => {
  const navigateTo = href => {
    window.location.href = href
  }

  return (
    <div className='volo-Footer'>
      <div className='volo-Footer-copyright'>Volo LLC © 2021 · <div className='volo-Footer-link' onClick={() => navigateTo('https://app.termly.io/document/privacy-policy/87a8ed34-3904-4039-bdbe-0b53880deb19')}>Privacy Policy</div> · <div className='volo-Footer-link' onClick={() => navigateTo('mailto:contact@flyvolo.co')}>Contact</div>
      </div>
    </div>
  )
}

export default Footer
